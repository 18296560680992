import axios from "axios";
import Velocity from "velocity-animate";

document.querySelectorAll(".subscribe_form").forEach(function(el) {
  el.addEventListener("submit", function(e) {
    e.preventDefault();
    el.children[2].value = "Adding...";

    axios
      .post(
        "https://wt-ff3817af3578260c2030e3e719928b31-0.sandbox.auth0-extend.com/gist-promo-subscribe",
        {
          email: el.children[0].value,
          name: el.children[1].value || ""
        }
      )
      .then(function(res) {
        console.log(res);
        el.children[0].disabled = true;
        el.children[1].disabled = true;
        el.children[2].value = "✓";
        el.children[2].disabled = true;
      })
      .catch(function(error) {
        console.log(error);
      });
  });
});

function calculateChildHeight(childIndex, acc = 0) {
  if (childIndex === -1) {
    return parseInt(acc * 0.95);
  } else {
    let child = document.getElementById("demo-chat").children[childIndex];
    let childStyles = window.getComputedStyle(child);
    let inner = child.getBoundingClientRect().height;
    let margins = parseFloat(childStyles.getPropertyValue("margin-top"));
    let padding = parseFloat(childStyles.getPropertyValue("padding-top"));
    let childHeight = inner + margins + padding;
    return calculateChildHeight(childIndex - 1, acc + childHeight);
  }
}

function startAnimation(delay = 0) {
  let screenHeight =
    document.getElementById("demo-screen").getBoundingClientRect().height - 12;

  Velocity(
    document.getElementById("demo-chat"),
    {
      translateY: screenHeight,
      opacity: 0
    },
    { duration: delay, delay: delay / 2 }
  );

  let nodeList = [];
  document.getElementById("demo-chat").childNodes.forEach(function(el, i) {
    if (el.nodeName == "#text") {
      return;
    } else {
      nodeList.push(el);
    }
  });

  nodeList.forEach(function(el, i) {
    Velocity(
      document.getElementById("demo-chat"),
      {
        translateY: screenHeight - calculateChildHeight(i),
        opacity: 1
      },
      {
        duration: 300,
        delay: delay + 300 * (i + 1) * (i % 2 == 0 ? 3 : 1),
        easing: "easeInOutSine"
      }
    );
  });
  Velocity(
    document.getElementById("demo-chat"),
    {
      translateY: screenHeight - calculateChildHeight(nodeList.length - 1),
      opacity: 0
    },
    {
      duration: 500,
      delay:
        1000 +
        delay +
        300 * nodeList.length * (nodeList.length - (1 % 2) == 0 ? 3 : 1),
      easing: "easeInOutSine",
      complete: function(elements) {
        startAnimation(500);
      }
    }
  );
}

startAnimation();
